.delete-message-container {
  padding-top: 1rem;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 1rem;
}

.delete-message-container > p {
  text-align: center;
  font-size: 1.1rem;
}

.delete-message-container > p > strong {
  font-weight: 600;
}

.delete-message-container > button {
  height: 2.5rem;
  width: 50%;
  background-color: var(--red);
  color: var(--white);
  box-shadow: 1px 1px 4px 0 var(--box-shadow-colour);
  border: none;
  border-radius: 0.5rem;
  justify-self: center;
  align-self: end;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}

.delete-message-container > button:disabled {
  background-color: var(--disabled-bg-colour);
  color: var(--disabled-font-colour);
  cursor: default;
}