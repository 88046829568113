.get-started-section {
  height: calc(100vh - 4.5rem - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.get-started-section > p {
  font-size: 1.15rem;
  width: calc(100% - 1rem);
  max-width: 500px;
}

@media only screen and (min-width: 768px) {
  .get-started-section > p {
    width: 60%;
  }
}

@media only screen and (min-width: 1024px) {
  .get-started-section > p {
    width: 40%;
  }
}

.get-started-section button {
  transform: scale(75%);
  transform-origin: bottom;
  display: inline-flex;
  margin: 0 0.25rem;
}