.share-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  grid-area: 1 / 1;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 100%;
  background-color: var(--header-bg-colour);
  backdrop-filter: blur(2px);
  border-radius: 0.5rem;
}

.share-icon-container {
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share-btn {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-bg-colour);
  box-shadow: 0 0 4px 0 var(--box-shadow-colour);
  border: none;
  border-radius: 0.5rem;
  font-size: 0.85rem;
}

.fa-copy, .fa-comment {
  font-size: 1.5rem;
}

.fa-whatsapp {
  font-size: 1.75rem;
}

.icon-text {
  cursor: default;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}