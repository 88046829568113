#action-buttons {
  display: grid;
  grid-template-columns: repeat(2, 2.25rem);
  column-gap: 0.5rem;
  z-index: 30;
}

.add-event-btn > span {
  font-variation-settings:
  'FILL' 0,
  'wght' 700,
  'GRAD' 0,
  'opsz' 48
}

.menu-icon {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url('../icons/menu-icon-1.png');
  background-size: cover;
}

.menu-btn > .menu-icon.menu-icon-active {
  background-image: url('../icons/menu-icon-4.png');
}

@media (prefers-color-scheme: dark) {
  .menu-icon {
    background-image: url('../icons/menu-icon-dark-1.png');
  }
  
  .menu-btn > .menu-icon.menu-icon-active {
    background-image: url('../icons/menu-icon-dark-4.png');
  }
}