main {
  min-height: calc(100vh - 3rem);
  padding-top: 4.5rem;
}

.events-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main h1 {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

article.month-events {
  margin-bottom: 1.2rem;
  padding: 0.75rem 0.5rem;
  width: calc(100% - 1rem);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-colour);
  box-shadow: 1px 1px 4px 0 var(--box-shadow-colour);
  border-radius: 0.5rem;
}

article.month-events > h2 {
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 0.25rem;
}

@media only screen and (min-width: 768px) {
  article.month-events {
    padding: 0.75rem;
    width: 60%;
  }
}

@media only screen and (min-width: 1024px) {
  article.month-events {
    width: 40%;
  }
}
