.create-message-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-container {
  display: grid;
  box-shadow: 1px 1px 4px 0 var(--box-shadow-colour);
  border-radius: 0.5rem;
}

.message-container > p {
  grid-area: 1 / 1;
  font-size: 1.1rem;
  padding: 0.5rem;
  cursor: pointer;
}

.info-text {
  align-self: flex-end;
  padding: 0.25rem 0.25rem 0;
  font-size: 0.85rem;
  color: var(--disabled-font-colour);
}

#user-input {
  margin-top: 1rem;
  min-height: 2.5rem;
  height: 2.5rem;
  max-height: 6rem;
  width: 100%;
  background-color: var(--main-bg-colour);
  box-shadow: 1px 1px 4px 0 var(--light-box-shadow-colour);
  border: 1px solid var(--line-colour);
  border-radius: 0.5rem;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 1.1rem;
  padding: 0.5rem;
  resize: none;
}

#user-input:focus{
  outline: 2px solid var(--blue);
  caret-color: var(--blue);
}

.create-message-container > button {
  height: 2.5rem;
  width: 50%;
  background-color: var(--blue);
  color: var(--white);
  box-shadow: 1px 1px 4px 0 var(--box-shadow-colour);
  border: none;
  border-radius: 0.5rem;
  align-self: center;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-message-container > button:disabled {
  background-color: var(--disabled-bg-colour);
  color: var(--disabled-font-colour);
  cursor: not-allowed;
}