.action-btn {
  height: 2.25rem;
  width: 2.25rem;
  background-color: var(--main-bg-colour);
  box-shadow: 0 0 4px 0 var(--box-shadow-colour);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.action-btn:hover {
  background-color: var(--hover-btn-colour);
}

.action-btn:hover span {
  transform: scale(1.1);
}