#modal-container {
  background-color: var(--modal-bg-colour);
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window {
  background-color: var(--main-bg-colour);
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 1rem;
  width: calc(100% - 1rem);
  max-width: 500px;
}

@media only screen and (min-width: 768px) {
  .modal-window {
    padding: 0.75rem 0.75rem 1rem;
    width: 60%;
  }
}

@media only screen and (min-width: 1024px) {
  .modal-window {
    width: 40%;
  }
}

.modal-title-container {
  display: grid;
  grid-template-columns: 2.25rem 8fr 2.25rem;
  justify-content: center;
  align-items: center;
}

.modal-title-container > h2 {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  grid-column: 2;
}
