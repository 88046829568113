footer {
  background-color: var(--main-bg-colour);
  box-shadow: 0 0 4px 0 var(--box-shadow-colour);
  height: 3rem;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  footer {
    padding: 0 1rem;
    font-size: 0.9rem;
    border-top: none;
  }
}