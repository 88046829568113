.menu-modal {
  position: absolute;
  top: 3.5rem;
  right: 0;
  width: 100vw;
  height: calc(100vh - 3.5rem);
  background-color: var(--modal-bg-colour);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.menu-options-container {
  background-color: var(--main-bg-colour);
  box-shadow: 1px 1px 4px 0 var(--box-shadow-colour);
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 0.25rem 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 7rem;
  max-width: 7rem;
}

@media only screen and (min-width: 768px) {
  .menu-options-container {
    margin: 0.75rem 1rem;
  }
}

#log-out-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-bg-colour);
  border: none;
  border-radius: 0.5rem;
  padding: 0.55rem;
  cursor: pointer;
  font-size: 1rem;
}

#log-out-btn:hover {
  background-color: var(--hover-btn-colour);
}

#log-out-btn > .material-symbols-outlined {
  font-size: 1.25rem;
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 24
}
