header {
  position: fixed;
  width: 100%;
  background-color: var(--header-bg-colour);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 4px 0 var(--box-shadow-colour);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  z-index: 20;
}

#logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
}

.menu-container {
  height: 3.5rem;
  display: flex;
  justify-content: right;
  align-items: center;
}

.menu-container > p {
  display: none;
  font-size: 1.1rem;
  font-weight: 500;
  padding-right: 0.75em;
  cursor: default;
}

@media only screen and (min-width: 768px) {
  header {
    padding: 0 1rem;
  }

  .menu-container > p {
    display: block;
  }
}
