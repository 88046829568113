#login-main {
  width: 100%;
  min-height: calc(100vh - 3rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  background-color: var(--main-bg-colour);
  box-shadow: 1px 1px 4px 0 var(--light-box-shadow-colour);
  border-radius: 0.5rem;
  padding: 1rem 1rem 1.25rem;
  width: calc(100% - 1rem);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.login-container > h1 {
  font-size: 1.3rem;
  padding-bottom: 1rem;
}

.google-icon {
  height: 1.5rem;
  width: 1.5rem;
  padding-right: 0.5rem;
}

.login-container > p {
  padding: 1rem;
}

.login-container button {
  align-self: end;
  height: 2.5rem;
  width: 75%;
  max-width: 16rem;
  background-color: var(--main-bg-colour);
  box-shadow: 1px 1px 4px 0 var(--light-box-shadow-colour);
  border: 1px solid var(--line-colour);
  border-radius: 0.5rem;
  align-self: center;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container button:hover {
  background-color: var(--hover-btn-colour);
}